import React from "react";
import CeWrapper from "../../global/CeWrapper";
import ArticleListTeaser from "../../../../articles/components/articleList/ArticleListTeaser/index";

const ce = ({ ceData }) => {
  return (
    <CeWrapper ceData={ceData} renderHeader={true}>
      <ArticleListTeaser
        layout={ceData.properties?.layout}
        rubrik={ceData.contentParts?.rubrik}
        lawFirms={ceData.contentParts?.lawFirms}
        themen={ceData.contentParts?.themen}
        itemCount={ceData.contentParts?.itemCount}
        urlConfig={ceData.contentParts?.urlConfig}
        leadFilterItems={ceData.contentParts?.leadFilterItems}
      />
    </CeWrapper>
  );
};
export default ce;
